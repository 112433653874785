define([
    'coreUtils'
], function (
    coreUtils
) {
    'use strict';

    const checkerMap = {};

    coreUtils.sessionFreezer.freeze(checkerMap);

    const registerCheckerForCompType = (type, requestsGetter) => {
        checkerMap[type] = requestsGetter;
    };

    const getCheckerForCompType = type => checkerMap[type];

    return {
        registerCheckerForCompType,
        getCheckerForCompType
    };
});
